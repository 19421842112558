// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-unidades-js": () => import("./../src/templates/unidades.js" /* webpackChunkName: "component---src-templates-unidades-js" */),
  "component---src-templates-detalhes-noticia-js": () => import("./../src/templates/detalhes-noticia.js" /* webpackChunkName: "component---src-templates-detalhes-noticia-js" */),
  "component---src-templates-landing-page-js": () => import("./../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-landing-page-old-redir-js": () => import("./../src/templates/landing-page-old-redir.js" /* webpackChunkName: "component---src-templates-landing-page-old-redir-js" */),
  "component---src-templates-exames-js": () => import("./../src/templates/exames.js" /* webpackChunkName: "component---src-templates-exames-js" */),
  "component---src-templates-detalhes-boletim-js": () => import("./../src/templates/detalhes-boletim.js" /* webpackChunkName: "component---src-templates-detalhes-boletim-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boletim-medico-js": () => import("./../src/pages/boletim-medico.js" /* webpackChunkName: "component---src-pages-boletim-medico-js" */),
  "component---src-pages-busca-js": () => import("./../src/pages/busca.js" /* webpackChunkName: "component---src-pages-busca-js" */),
  "component---src-pages-cadastro-medico-js": () => import("./../src/pages/cadastro-medico.js" /* webpackChunkName: "component---src-pages-cadastro-medico-js" */),
  "component---src-pages-comunicado-de-privacidade-js": () => import("./../src/pages/comunicado-de-privacidade.js" /* webpackChunkName: "component---src-pages-comunicado-de-privacidade-js" */),
  "component---src-pages-convenios-js": () => import("./../src/pages/convenios.js" /* webpackChunkName: "component---src-pages-convenios-js" */),
  "component---src-pages-direitos-dos-pacientes-js": () => import("./../src/pages/direitos-dos-pacientes.js" /* webpackChunkName: "component---src-pages-direitos-dos-pacientes-js" */),
  "component---src-pages-exames-js": () => import("./../src/pages/exames.js" /* webpackChunkName: "component---src-pages-exames-js" */),
  "component---src-pages-fale-conosco-js": () => import("./../src/pages/fale-conosco.js" /* webpackChunkName: "component---src-pages-fale-conosco-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institucional-js": () => import("./../src/pages/institucional.js" /* webpackChunkName: "component---src-pages-institucional-js" */),
  "component---src-pages-medico-js": () => import("./../src/pages/medico.js" /* webpackChunkName: "component---src-pages-medico-js" */),
  "component---src-pages-noticias-js": () => import("./../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-portal-de-privacidade-js": () => import("./../src/pages/portal-de-privacidade.js" /* webpackChunkName: "component---src-pages-portal-de-privacidade-js" */),
  "component---src-pages-unidades-js": () => import("./../src/pages/unidades.js" /* webpackChunkName: "component---src-pages-unidades-js" */)
}

